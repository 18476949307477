.plans-container {
    display: flex;
    flex-direction: column;
    margin-top: -5rem;
    gap: 5rem;
}

.plans-heading {
    display: flex;
    gap: 2rem;
    font-size: 3rem;
    color: white;
    text-transform: uppercase;
    justify-content: center;
    font-weight: bold;
    font-style: italic;
}

.plans-category {
    display: flex;
    gap: 4rem;
    justify-content: center;
}

.plan-card {
    background-color: var(--caloryCard);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
}

.plan-card>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}

.plan-card>:nth-child(2) {
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.plan-card>:nth-child(3) {
    color: white;
    font-size: 2rem;
    font-weight: bold;
}

.plan-features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: white;
}

.feature>img {
    width: 1rem;
    height: 1rem;
}

.feature>span {
    width: 85%;
}

.plan-card>:nth-child(5) {
    color: white;
    text-transform: capitalize;
    font-size: 0.85rem;
}

.plans-category>:nth-child(2) {
    background-color: var(--orange);
    transform: scale(1.22);
}

.plans-category>:nth-child(2)>:nth-child(1) {
    fill: white;
}

.plan-card>button {
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .plans-heading {
        flex-direction: column;
        gap: 1rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .plans-category {
        flex-direction: column;
        padding: 2rem;
    }

    .plans-category>:nth-child(2){
        transform: none;
    }
}