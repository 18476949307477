.Reasons-container {
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
}

.left-reasons {
    flex: 1 1;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
}

.right-reasons {
    flex: 1 1;
}

.left-reasons>:nth-child(1) {
    grid-row: 1/3;
    width: 12rem;
    height: 28rem;
    object-fit: cover;
}

.left-reasons>:nth-child(2) {
    width: auto;
    height: 16rem;
    object-fit: cover;
    grid-column: 2/4;
}

.left-reasons>:nth-child(3) {
    width: auto;
    height: 11rem;
    object-fit: cover;
    grid-column: 2/3;
    grid-row: 2;
}

.left-reasons>:nth-child(4) {
    width: 10.5rem;
    height: 11rem;
    object-fit: cover;
    grid-column: 3/4;
    grid-row: 2;
}

.right-reasons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right-reasons>:nth-child(1) {
    font-size: 1em;
    color: var(--orange);
    font-weight: bold;
    text-transform: uppercase;
}

.reasons-text {
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.reason-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.reason-details>div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.reason-details img {
    width: 2rem;
    height: 2rem;
}

.reason-details span {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.right-reasons>:nth-child(4) {
    text-transform: uppercase;
    color: var(--gray);
}

.partners {
    display: flex;
    gap: 1rem;
}

.partners>img {
    height: 2rem;
}

@media screen and (max-width: 768px) {
    .Reasons-container {
        flex-direction: column;
    }

    .reasons-text {
        flex-direction: column;
    }

    .left-reasons {
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-reasons>:nth-child(1) {
        width: 7rem;
        height: 22rem;
    }

    .left-reasons>:nth-child(2) {
        width: 15rem;
        height: 10rem;
    }

    .left-reasons>:nth-child(3) {
        width: 7rem;
    }
}